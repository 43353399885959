import {renderRichText, storyblokEditable} from '@storyblok/react'
import React from 'react'
import {
  CurrentCustomersBlockStoryblok,
  CurrentCustomersEntryStoryblok,
} from 'types/storyblok-types'
import {HeaderBlock, HeaderType} from './Header'
import Link from 'next/link'
import BackgroundMapper from 'util/BackgroundMapper'
import linkTypeChecker from 'util/linkTypeChecker'

type CurrentCustomerBlock = {
  text?: string
  title: string
  customer_entries: CurrentCustomersEntryStoryblok[]
  blok: CurrentCustomersBlockStoryblok
  backgroundColor: CurrentCustomersBlockStoryblok['background_color'][0]['background_color']
}

export const CurrentCustomerBlock = ({
  text,
  title,
  customer_entries,
  blok,
  backgroundColor,
}: CurrentCustomerBlock) => {
  return (
    <div
      className={`flex flex-col ${BackgroundMapper(
        backgroundColor,
      )} rounded-dats-xl  px-5 py-8 md:flex-row md:gap-5 md:px-24 md:py-8`}
      {...storyblokEditable(blok)}
    >
      <div className="md:w-3/5">
        <HeaderBlock title={title} type={HeaderType.h2} />
        {text ? <div dangerouslySetInnerHTML={{__html: text}}></div> : null}
      </div>
      <div className="mt-10 flex flex-col gap-6 md:mt-0 md:w-2/5">
        {customer_entries.map(entry => (
          <Link
            key={entry._uid}
            className="block text-base font-semibold text-dats-s5 no-underline"
            href={linkTypeChecker(entry.link)}
            target={entry.link.target}
          >
            <button
              type="button"
              className="flex w-full items-center justify-between"
            >
              <span className="mr-5 inline-block text-left">{entry.label}</span>
              <span className="size-9 rounded-dats bg-dats-s3">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none">
                  <path
                    d="M21.6347 18.5542C22.0176 18.1652 22.0176 17.5334 21.6347 17.1444L15.753 11.1692C15.3701 10.7802 14.7482 10.7802 14.3653 11.1692C13.9824 11.5582 13.9824 12.19 14.3653 12.579L19.5547 17.8508L14.3684 23.1227C13.9855 23.5117 13.9855 24.1435 14.3684 24.5325C14.7513 24.9215 15.3732 24.9215 15.7561 24.5325L21.6377 18.5573L21.6347 18.5542Z"
                    fill="#F0FCFF"
                  />
                </svg>
              </span>
            </button>
          </Link>
        ))}
      </div>
    </div>
  )
}

const CurrentCustomerBlockContainer = ({
  blok,
}: {
  blok: CurrentCustomersBlockStoryblok
}) => {
  return (
    <CurrentCustomerBlock
      title={blok.title}
      text={renderRichText(blok.text)}
      customer_entries={blok.current_customers_entries}
      blok={blok}
      backgroundColor={
        blok.background_color.length > 0
          ? blok.background_color[0].background_color
          : 'white'
      }
    />
  )
}

export default CurrentCustomerBlockContainer
